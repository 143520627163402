'use strict';

Gri.module({
  name: 'button-delta',
  ieVersion: null,
  $el: $('.button-delta'),
  container: '.button-delta',
  fn: function () {
    
  }
});
